import React from "react";
import styles from "./modal.module.css";

const Modal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div onClick={props.onClose} className={styles["modal"]}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles["modal-body"]}
      >
        {props.content}
      </div>
    </div>
  );
};

export default Modal;
