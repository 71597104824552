import React from "react";
import { Helmet } from "react-helmet";

import projectStyles from "../style.module.css";
import styles from "./realizacje.module.css";
import Opinie from "./opinie";

function Realizacje() {
  return (
    <div className={projectStyles["container"]}>
      <Helmet>
        <title>Realizacje - PM Automation - automatyka HVAC</title>
      </Helmet>
      <div className={projectStyles["top-margin"]}/>
      <div className={styles["realizacje-container"]}>
        <div className={projectStyles["tytul-container"]}>
          <div className={projectStyles["tytul"]}>
            Realizacje
          </div>
          <div className={projectStyles["podtytul"]}>
            Sprawdź efekty naszej pracy!
          </div>
        </div>
        <div className={projectStyles["tekst"]}>
          <div className={projectStyles["akapit-just"]}>
              Z dumą prezentujemy efekty naszych prac w&nbsp;dziedzinie automatyki HVAC. 
              Nasze realizacje to wyraz zaangażowania w&nbsp;tworzenie zaawansowanych systemów 
              sterowania, które przynoszą realne korzyści. Od rozbudowanych projektów grzewczych 
              przez innowacyjne rozwiązania wentylacyjne, aż po naprawę starych i&nbsp;wysłużonych urządzeń. 
              Każda realizacja to dowód naszej zdolności dostarczania efektywnych rozwiązań.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Każdy projekt to wyzwanie, które przyjęliśmy z radością. Dzięki naszemu doświadczeniu 
              oraz zaawansowanym technologiom, tworzymy systemy, które są precyzyjne, niezawodne 
              i&nbsp;przynoszą oszczędności energetyczne. Nasza współpraca z&nbsp;lokalnymi firmami przynosi 
              wymierne korzyści klientom, którzy zaufali naszym umiejętnościom.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Zapraszamy Cię do zapoznania się z&nbsp;naszymi realizacjami, aby zobaczyć, 
              jakie projekty udało nam się wykonać. To dla naszych klientów dowód na to, 
              że nasza pasja i&nbsp;zaangażowanie przekładają się na wyjątkowe rezultaty.
            </div>
        </div>
        <div className={projectStyles["naglowek"]}>
          Wybrane projekty
        </div>
        <div className={styles["realizacje"]}>
          <div id="market1" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Hipermarket"
                  src="/playground_assets/1691864442163.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Hipermarket - Warszawa
              </div>
                <div className={styles["opis-karta"]}>
                  Zaprojektowaliśmy i&nbsp;stworzyliśmy część elektryczną dla
                  chłodnictwa w&nbsp;Hipermarkecie. Projekt 9&nbsp;szaf sterowniczych 
                  obsługujących ponad 30 chłodni i&nbsp;mroźni, w&nbsp;których 
                  znajduje się ponad 50 chłodnic na CO<sub>2</sub>. Całość została spięta 
                  w&nbsp;system monitoringu oparty na Carel BOSS.
                </div>
          </div>
          <div id="pieczarki" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Pieczarki"
                  loading="lazy"
                  src="/playground_assets/1691864441942.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Produkcja pieczarek - Wisła Wielka
              </div>
              <div className={styles["opis-karta"]}>
                Stworzyliśmy układ sterujący odzyskiem ciepła z&nbsp;agregatów 
                wody lodowej w&nbsp;zakładzie produkującym pieczarki. Układ dodatkowo
                zintegrowany jest z&nbsp;instalacją fotowoltaiczną, dzięki czemu 
                w&nbsp;momencie nadprodukcji energii elektrycznej załączane zostają 
                grzałki, aby maksymalnie wykorzystać dostępną energię.
              </div>
          </div>
          <div id="market2" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Hipermarket"
                  src="/playground_assets/1691864442201.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Hipermarket - Warszawa
              </div>
                <div className={styles["opis-karta"]}>
                  Zaprojektowaliśmy i&nbsp;stworzyliśmy zespół rozdzielni 
                  zasilających część odbiorową dla chłodnictwa w&nbsp;hipermarkecie. 
                  Rozdzielnice zasilają kilkadziesiąt mebli chłodniczych oraz 9 
                  szaf odbiorowych dla chłodni. W każdej z&nbsp;nich znajduje się 
                  dodatkowo sterownik chłodnicy maszynowni.
                </div>
          </div>
          <div id="gospodarstwo" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Gospodarstwo"
                  src="/playground_assets/grzawa2.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Gospodarstwo Rolne - Grzawa
              </div>
                <div className={styles["opis-karta"]}>
                  Zaprojektowaliśmy i&nbsp;stworzyliśmy układ sterowania do freecoolingu 
                  zimowej przechowalni warzyw. Regulacja pracą systemu przepustnic  
                  i&nbsp;wentylatorów ma na celu utrzymanie temperatury produktu przy 
                  wykorzystaniu wyłącznie zimnego powietrza zewnętrznego, i&nbsp;to bez
                  układu freonowego! Takie rozwiązanie to ogromna oszczędność energii
                  oraz znacznie mniejszy koszt inwestycji.
                </div>
          </div>
          <div id="szkola" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Szkoła"
                  loading="lazy"
                  src="/playground_assets/1691864442022.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Szkoła - Krapkowice
              </div>
              <div className={styles["opis-karta"]}>
                Zaprojektowaliśmy i&nbsp;zbudowaliśmy układ sterujący instalacją 
                centralnego ogrzewania dla budynku zespołu szkolno-przedszkolnego.
                Układ reguluje w&nbsp;trybie pogodowym pracą pięciu niezależnych obiegów 
                grzewczych oraz instalacją ciepłej wody użytkowej we współpracy 
                z&nbsp;pompą ciepła. Obsługa instalacji odbywa się z&nbsp;poziomu 
                dużego, wygodnego panelu dotykowego.
              </div>
          </div>
          <div id="laboratorium" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Laboratorium"
                  loading="lazy"
                  src="/playground_assets/1691864441870.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Laboratorium - Tychy
              </div>
              <div className={styles["opis-karta"]}>
                Wymieniliśmy uszkodzony sterownik Carel pCO w&nbsp;rozdzielnicy 
                zasilająco-sterującej  centrali wentylacyjnej obsługującej 
                pomieszczenia laboratorium. Oprócz wymiany samego sterownika 
                realizacja wymagała stworzenia oprogramowania na sterownik PLC 
                pod istniejący układ wentylacyjny wraz z&nbsp;adaptacją wydatku 
                centrali w&nbsp;zależności od ilości pracujących dygestoriów.
              </div>
          </div>
          <div id="swiece" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Produkcja Swiec"
                  loading="lazy"
                  src="/playground_assets/1691864441844.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Zakład produkcyjny - Czechowice
              </div>
              <div className={styles["opis-karta"]}>
                Wymieniliśmy uszkodzony sterownik Carrier Pro Dialog w&nbsp;przemysłowym 
                klimatyzatorze. Zamontowaliśmy nowy sterownik Carel uChiller z&nbsp;możliwością
                połączenia się smartfonem przez Bluetooth. Niezbędna była modyfikacja 
                połączeń elektrycznych i&nbsp;wymiana wszystkich czujników na inny typ.
              </div>
          </div>
          <div id="urzad" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Urząd Miasta"
                  loading="lazy"
                  src="/playground_assets/1691864441876.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Urząd Miasta - Pszczyna
              </div>
                <div className={styles["opis-karta"]}>
                  Wykonaliśmy rozdzielnicę sterującą pracą klimatyzatorów w&nbsp;serwerowni 
                  Urzędu Miasta. Redundantny układ, który przełącza pracujące urządzenie w&nbsp;określonych 
                  cyklach czasowych. W&nbsp;razie awarii następuje załączenie drugiego klimatyzatora. 
                  Gdy temperatura w&nbsp;pomieszczeniu przekroczy określony poziom, uruchomione zostają oba urządzenia.
                </div>
          </div>
          <div id="restauracja" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Restauracja Kobiór"
                  loading="lazy"
                  src="/playground_assets/tatrzanska-1500w.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Restauracja - Kobiór
              </div>
                <div className={styles["opis-karta"]}>
                  Zaprojektowaliśmy, zbudowaliśmy i&nbsp;wymieniliśmy 
                  układ sterujący pracą centrali wentylacyjnej obsługującej
                  salę restauracyjną. Centrala wyposażona w&nbsp;chłodnicę DX 
                  z&nbsp;freonowym agregatem skraplającym, glikolowy wymiennik 
                  ciepła i&nbsp;układ recyrkulacji powietrza.
                </div>
          </div>
          <div id="market" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Market Zabrze"
                  loading="lazy"
                  src="/playground_assets/obi-1500w.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Hipermarket - Zabrze
              </div>
              <div className={styles["opis-karta"]}>
                Naprawiliśmy istniejący układ sterowania nagrzewnicami 
                i&nbsp;centralami wentylacyjnymi. Naprawa polegała na 
                zdiagnozowaniu uszkodzonych podzespołów i&nbsp;przywróceniu 
                pełnej sprawności pracy systemu.
              </div>
          </div>
          <div id="browar" className={styles["karta"]}>
              <div className={styles["ramka-karta"]}>
                <img
                  alt="Browar Pszczyna"
                  loading="lazy"
                  src="/playground_assets/piano.png"
                  className={styles["obraz-karta"]}
                />
              </div>
              <div className={styles["tytul-karta"]}>
                Browar - Pszczyna
              </div>
              <div className={styles["opis-karta"]}>
                Zaprojektowaliśmy i&nbsp;wykonaliśmy układ sterowania dla wentylacji 
                oraz awaryjnego przewietrzania pomieszczeń warzelni browaru. 
                Całość pracuje w&nbsp;oparciu o&nbsp;pomiar stężenia CO<sub>2</sub>.
                W&nbsp;przypadku przekroczenia progu alarmowego uruchomiony zostaje
                układ przewietrzania pomieszczeń.
              </div>
          </div>
        </div>
      </div>
      <div className={styles["opinie"]}>
        <div className={styles["max-content-width-container"]}>
          <div className={projectStyles["tytul"]}>
            Opinie naszych klientów
          </div>
          <div className={projectStyles["podtytul"]}>
            Każda opinia jest dla nas ważna
          </div>
          <div className={styles["opinie-google"]}>
            <Opinie />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Realizacje;
