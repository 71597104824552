import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styles from "./404.module.css";
import projectStyles from "../style.module.css";

function NotFound() {

    // Pobranie wysokości okna
    const [height, setHeight] = useState(window.innerHeight);

    // Funkcja ładujące zewnętrzne skrypty JS
    useEffect(() => {
        // Załaduj zewnętrzny skrypt Three.js
        const script1 = document.createElement('script');
        const script2 = document.createElement('script');
        const script3 = document.createElement('script');
        script1.src = './js/jquery-1.12.0.min.js';
        script2.src = './js/three.js';
        script3.src = './js/camera.js';
        script1.async = true;
        script2.async = true;
        script3.async = true;

        // Utwórz funkcję, która załaduje skrypt camera.js po załadowaniu Three.js
        const loadCameraScript = () => {
            // Dodaj skrypt do strony
            document.body.appendChild(script3);
        };

        // Dodaj event listener do skryptu Three.js
        script1.addEventListener('load', loadCameraScript);

        // Dodaj skrypt Three.js do strony
        document.body.appendChild(script1);
        document.body.appendChild(script2);

        // Czyść skrypty po odmontowaniu komponentu
        return () => {
            script1.removeEventListener('load', loadCameraScript);
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    // Funkcja ustawiająca wysokość elementu #particles

    useEffect(() => {
        // Odświeżenie warsości wysokości oka po zmianie jego wymiarów
        const handleResize = () => {
            setHeight(window.innerHeight);
        };
    
        window.addEventListener("resize", handleResize);
    
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
  
    // Pobranie wysokości elementów stałych, obliczenie wysokości dla elementu #particles i jej ustawienie
    useEffect(() => {
        const navbarHeight = document.getElementById("navbar")
            ? document.getElementById("navbar").offsetHeight
            : 0;
        const burgerMenuHeight = document.getElementById("burger-menu")
            ? document.getElementById("burger-menu").offsetHeight
            : 0;
        const stopkaHeight = document.getElementById("stopka")
            ? document.getElementById("stopka").offsetHeight
            : 0;
        const particles = document.getElementById("particles");
        if (particles) {
            var wysokosc = height - navbarHeight - burgerMenuHeight - stopkaHeight - 1;
            particles.style.height = `${wysokosc}px`;
        }
        console.log("Wysokość: ", height, "Navbar: ", navbarHeight, "Burger: ", burgerMenuHeight, "Stopka: ", stopkaHeight)
    }, [height]);

    return (
        <>
        <Helmet>
            <title>Błąd 404 - PM Automation - automatyka HVAC</title>
        </Helmet>
        <div className={styles["container"]}>
            <div id="particles"></div>
            <div className={styles["logo-container"]}>  
                <img
                    alt="logo"
                    className={styles["logo"]}
                    src="./playground_assets/logodb-transp.png" />
                <div className={styles["tekst"]}>
                    Strona do której próbujesz dotrzeć nie istnieje.
                </div>
                <Link to="/">
                    <button
                        type="button"
                        className={projectStyles["button-black"]}>
                        Strona główna
                    </button>
                </Link>
            </div>
        </div>
        </>
    );
}

export default NotFound;
