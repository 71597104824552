import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import projectStyles from "../style.module.css";
import styles from "./kontakt.module.css";
import { send } from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

function Kontakt() {

  //definicja zmiennych
  const alert = document.getElementById("alert");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [loadingBar, setLoadingBar] = useState(false);
  const [count, setCount] = useState(0);
  const [zweryfikowany, setZweryfikowany] = useState(false);
  const [toSend, setToSend] = useState({ //obiekt zawierający pola formularza
    name: "",
    organization: "",
    tel: "",
    email: "",
    message: "",
  });

  var maxChars = 500;                 //<== maksymalna liczba znaków w wiadomości//
  var charsLeft = maxChars - count;   //funkcja obliczająca ile zostało znaków do wpisania

  
  //funkcja przechowująca zawartość pól formularza
  function handleChange(e) {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  }

  // ===> funkcje niezbędne do prawidłowego działnania Google reCaptcha <=== //

  //funkcja wykonywana po wypełnieniu reCaptcha//
  function aktywujRecaptcha(value) {
    if (value !== 0) {
      setZweryfikowany(true);
      console.log("reCaptcha zweryfikowany");    
      if (submitDisabled) {
        setSubmitDisabled(false);
      };
      if (alert.style.display != "none") {
        alert.style.display = "none";
      };
    }
    else {
      console.log("reCaptcha niezweryfikowany");
    };    
  };

  //funkcja wykonywana po wygaśnięciu reCaptcha//
  function reCaptchaWygaslo() {
    console.log("Klucz reCaptcha stracił ważność");
    if (! submitDisabled) {
      setSubmitDisabled(true);
    };
    if (alert.style.display != "flex") {
      alert.style.display = "flex";
    };
    //reCaptcha niezweryfikowany//
    setZweryfikowany(false);
  };
  
  //funkcja wykonywana prrzy błędzie reCaptcha//
  function bladRecaptcha() {
    console.log("Błąd weryfikacji reCaptcha");
    if (! submitDisabled) {
      setSubmitDisabled(true);
    };
    if (alert.style.display != "flex") {
      alert.style.display = "flex";
    };
    //reCaptcha niezweryfikowany//
    setZweryfikowany(false);;
  };

  //funkcja resetująca i wyświetlająca tekst reCaptcha //
  function reCaptchaReset() {
    console.log("Zresetowano reCaptcha");
    if (zweryfikowany) {
      window.grecaptcha.reset();
    };
    if (! submitDisabled) {
      setSubmitDisabled(true);
    };
    if (alert.style.display != "flex") {
      alert.style.display = "flex";
    };
    setZweryfikowany(false);
  };

  // ===> funkcje pozostałe <=== //

  //funkcja czyszcząca formularz
  function formularzReset() {
    if ((toSend.name.length > 0) || (toSend.organization.length > 0) ||(toSend.tel.length > 0) ||(toSend.email.length > 0) ||(toSend.message.length > 0)) {      
      setToSend({
        name: "",
        organization: "",
        tel: "",
        email: "",
        message: ""
      });
      setCount(0);
    }
  };

  //funkcja ukrycia wiadomości o wysłaniu maila
  function timerSent() {
    const timer = setTimeout(() => {
      setEmailSent(false);
    }, 10000);
    return () => clearTimeout(timer);
  }
  //funkcja ukrycia wiadomości o niepowodzeniu
  function timerFailed() {
    setTimeout(() => {
      setEmailFailed(false);
    }, 10000);
    return () => clearTimeout(timerFailed);
  };

  //funkcja wykonywana po wciśnięciu przycisku wyślij//
  const onSubmit = (e) => {
    e.preventDefault(); //blokada domyślnego zachowania
    setSubmitDisabled(true);
    setLoadingBar(true);
    if (zweryfikowany == true) {
      send(
        "pmautomation", //userName
        "template_fbpyr82", //templateID
        toSend, //wartości do przesłania
        "user_Yanr702lijfrGEZPJfQyF" //userID
      )
        .then((response) => {
          console.log("Mail wysłany", response.status, response.text);
          setEmailSent(true);
          alert.style.display = "flex";
          reCaptchaReset();
          formularzReset();         
          setLoadingBar(false);
          timerSent();
        })
        .catch((err) => {
          console.log("Błąd wysyłania. Wiadomość nie została wysłana", err);
          setEmailFailed(true);        
          setLoadingBar(false);
          timerFailed();
        });
      } else {
        console.log("Formularz zawiera błędy lub nie został wypełniony poprawnie");
      }
  };

  //strona//
  return (
    <div className={projectStyles["container"]}>
      <Helmet>
        <title>Kontakt - PM Automation - automatyka HVAC</title>
      </Helmet>
      <div className={projectStyles["top-margin"]}/>
      <div className={styles["kontakt"]}>
        <div className={projectStyles["tytul-container"]}>
          <div className={projectStyles["tytul"]}>
            Kontakt
          </div>
          <div className={projectStyles["podtytul"]}>
            Jesteśmy tu, aby Ci pomóc!
          </div>
        </div>
        <div className={projectStyles["tekst"]}>
          <div className={projectStyles["akapit-just"]}>
            Jeśli szukasz zaawansowanych rozwiązań w dziedzinie 
            automatyki HVAC, jesteśmy gotowi do działania. Skontaktuj się z&nbsp;nami, abyśmy 
            mogli dowiedzieć się o&nbsp;Twoich potrzebach i&nbsp;zaproponować najbardziej efektywne 
            rozwiązania. Czekamy na Twój telefon lub wiadomość e-mail, aby zaczęła się owocna współpraca!
          </div>
        </div>
      </div>
      <div className={projectStyles["tlo-srednie"]}>
        <div className={projectStyles["max-container"]}>
          <div className={projectStyles["produkty-container"]}>
            <div className={projectStyles["naglowek"]}>
              Dane naszej firmy:
            </div>
            <div className={projectStyles["produkty"]}>
              <div className={projectStyles["produkt"]}>
                <svg
                  viewBox="0 0 16 16"
                  className={projectStyles["ikona-produktu"]}
                >
                  <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z"/>
                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z"/>
                </svg>
                <div className={projectStyles["nazwa-produktu"]}>
                  Dane kontaktowe
                </div>
                <div className={projectStyles["opis-produktu"]}>
                  PM&nbsp;Automation&nbsp;- automatyka&nbsp;HVAC
                  <br></br>
                  Paweł Malcharek
                  <br></br>
                  NIP: 638&nbsp;180&nbsp;45&nbsp;65
                  <br></br>
                  REGON: 520&nbsp;011&nbsp;550
                </div>
              </div>
              <div className={projectStyles["produkt"]}>
                <svg
                  viewBox="0 0 16 16"
                  className={projectStyles["ikona-produktu"]}
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                </svg>
                <div className={projectStyles["nazwa-produktu"]}>
                  Dane teleadresowe
                </div>
                <div className={projectStyles["opis-produktu"]}>
                  43-210&nbsp;Kobiór
                  <br></br>
                  ul. Orla&nbsp;12
                  <br></br>
                  tel.: +48&nbsp;726&nbsp;377&nbsp;475
                  <br></br>
                  email: biuro@pmautomation.pl
                </div>
              </div>
              <div className={projectStyles["produkt"]}>
                <svg
                  viewBox="0 0 16 16"
                  className={projectStyles["ikona-produktu"]}
                >
                  <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>
                </svg>
                <div className={projectStyles["nazwa-produktu"]}>
                  Dane bankowe
                </div>
                <div className={projectStyles["opis-produktu"]}>
                  ING Bank Śląski S.A.
                  <br></br>
                  Numer konta: 23 1050&nbsp;1399 1000&nbsp;0097 4438&nbsp;3572
                  <br></br>
                  IBAN: PL23 1050&nbsp;1399 1000&nbsp;0097 4438&nbsp;3572
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["kontakt"]}>
        <div className={projectStyles["fiveunits-margin"]}></div>
        <div className={projectStyles["tytul"]}>
          Wiadomość
        </div>
        <div className={projectStyles["podtytul"]}>
          Wyślij do nas zapytanie - odpowiemy!
        </div>
        <div className={styles["wiadomosc-container"]}>
          <form
              id="form"
              name="form"
              className={styles["form"]}
              onSubmit={onSubmit}
            >
            <div className={styles["formularz-container"]}>
              <div className={styles["formularz"]}>
                <div className={styles["pole-formularz"]}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required={true}
                    maxLength="40"
                    placeholder="Imię i nazwisko*"
                    autoComplete="name"
                    value={toSend.name}
                    onChange={handleChange}
                    className={styles["input"]}
                  />
                  <svg
                    className={styles["ikona"]}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                  </svg>
                </div>
                <div className={styles["pole-formularz"]}>
                  <input
                    type="text"
                    id="organization"
                    name="organization"
                    maxLength="40"
                    placeholder="Firma"
                    autoComplete="organization"
                    value={toSend.organization}
                    onChange={handleChange}
                    className={styles["input"]}
                  />
                  <svg
                    className={styles["ikona"]}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/>
                    <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z"/>
                  </svg>
                </div>
                <div className={styles["pole-formularz"]}>
                  <input
                    type="tel"
                    id="tel"
                    name="tel"
                    required={true}
                    maxLength="13"
                    minLength="9"
                    placeholder="Nr telefonu*"
                    inputMode="numeric"
                    autoComplete="tel"
                    value={toSend.tel}
                    onChange={handleChange}
                    className={styles["input"]}
                  />
                  <svg
                    className={styles["ikona"]}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                  </svg>
                </div>
                <div className={styles["pole-formularz"]}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required={true}
                    maxLength="40"
                    minLength="5"
                    placeholder="e-mail*"
                    inputMode="email"
                    autoComplete="email"
                    value={toSend.email}
                    onChange={handleChange}
                    className={styles["input"]}
                  />
                  <svg
                    className={styles["ikona"]}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                    <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                  </svg>
                </div>
                <div className={styles["pole-formularz"]}>
                  <textarea
                    id="message"
                    name="message"
                    rows="5"
                    maxLength={maxChars}
                    minLength="20"
                    placeholder="Wiadomość*"
                    required={true}
                    value={toSend.message}
                    className={styles["input"]}
                    onChange={handleChange}
                    onKeyUp={(e) => {
                      setCount(e.target.value.length);
                      console.log("Napisano", count, "znaków");
                    }}
                  />
                  <svg
                    className={styles["ikona"]}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/>
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                  </svg>
                </div>
                <div className={styles["licznik"]}>
                  Pozostało {charsLeft} znaków.
                </div>
              </div>
              <div className={styles["wiadomosc"]}>
                <div className={styles["RODO"]}>
                  Klikając wyślij wyrażam zgodę na przetwarzanie moich danych osobowych 
                  w&nbsp;celu realizacji działań promocyjno-marketingowych przez firmę 
                  PM Automation - automatyka HVAC Paweł Malcharek za pośrednictwem adresu 
                  e-mail oraz numeru telefonu wskazanego w&nbsp;powyższym formularzu.
                </div>
                <div className={styles["RODO"]}>
                  Administratorem danych osobowych jest firma PM Automation - automatyka 
                  HVAC Paweł Malcharek, kontakt: biuro@pmautomation.pl, która przetwarza 
                  dane osobowe w&nbsp;celu podjęcia działań na Państwa żądanie przed 
                  zawarciem umowy tj. w&nbsp;celu odpowiedzi na pytania 
                  kierowane do nas za pośrednictwem formularza. Informujemy, że przysługuje Państwu prawo dostępu do swoich danych osobowych, 
                  ich sprostowania, ograniczenia przetwarzania, przenoszenia, wycofania zgody oraz złożenia skargi do organu nadzorczego. 
                  Szczegółowe informacje dostępne są w&nbsp;naszej Polityce prywatności.
                </div>
                <Link
                  to="/polityka"
                  className={styles["polityka"]}>
                    Więcej informacji
                </Link>
                <div className={styles["RODO"]}>
                  (Uwaga! Klikając w link formularz zostanie wyczyszczony.)
                </div>
                <div className={styles["recaptcha-container"]}> 
                  <ReCAPTCHA
                  sitekey="6LdrAlUeAAAAAGXuJVDHVaAuAMlG7QgHzIkTuLSO"
                  onChange={aktywujRecaptcha}
                  onExpired={reCaptchaWygaslo}
                  onErrored={bladRecaptcha}
                  />
                </div>
              </div>
            </div>
            <div id="alert" className={styles["alert"]}>
              Zanim wyślesz wiadomość, udowodnij, że nie jesteś robotem
              <svg viewBox="0 0 16 16" className={styles['alert-icon']}>
                <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z"/>
                <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z"/>
              </svg>
            </div>
            <div
            className={
              loadingBar 
                ? styles["loadingBar"]
                : styles["loadingBar-hidden"]
            }
            />
            <div
              className={
                emailSent
                  ? styles["wiadomoscWyslana"]
                  : styles["wiadomoscNieWyslana"]
              }
            >
              Dziękujemy! Twoja wiadomość została wysłana. Odpowiemy na nią
              tak szybko, jak to tylko możliwe.
            </div>
            <div
              className={
                emailFailed
                  ? styles["wiadomoscBlad"]
                  : styles["wiadomoscNieWyslana"]
              }
            >
              Coś poszło nie tak! Spróbuj ponownie.
            </div>
            <div className={projectStyles["button-container"]}>
              <button
                disabled={submitDisabled}
                id="submit"
                name="wyslij"
                type="submit"
                className={
                  submitDisabled
                  ? projectStyles["button-disabled"]
                  : projectStyles["button-white"]
                }
              >
                Wyślij
              </button>
              <button
                id="anuluj"
                name="anuluj"
                type="reset"
                className={projectStyles["button-black"]}
                onClick={() => {
                  reCaptchaReset();
                  formularzReset();
                }}
              >
                Anuluj
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className={projectStyles["tlo-jasne"]}>
        <div className={projectStyles["fiveunits-margin"]}></div>
        <div className={projectStyles["tytul-container"]}>
          <div className={projectStyles["tytul"]}>
            Mapa
          </div>
          <div className={projectStyles["podtytul"]}>
            Jak do nas trafić?
          </div>
        </div>
        <div className={styles["mapa"]}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.495938888463!2d18.92229491571724!3d50.058273179423146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716b72581044b33%3A0xaa4c5e7411a9c4fa!2sPM%20Automation%20-%20Automatyka%20HVAC!5e0!3m2!1spl!2spl!4v1643869168902!5m2!1spl!2spl"
            allowFullScreen=""
            loading="lazy"
            className={styles["mapa.dojazdu"]}
            title="mapa"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Kontakt;
