import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import projectStyles from "../style.module.css";
import styles from "./oferta.module.css";

function Oferta() {
  const [productWidth, setProductWidth] = useState('25%');

  useEffect(() => {
    const updateDivWidth = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 800) {
        setProductWidth('25%');
      } else if (windowWidth > 600) {
        setProductWidth('50%');
      } else {
        setProductWidth('100%');
      }
    };

    // Wywołujemy funkcję po załadowaniu komponentu i zmianie szerokości okna
    updateDivWidth();
    window.addEventListener('resize', updateDivWidth);

    // Czyszczenie event listenera przy odmontowywaniu komponentu
    return () => {
      window.removeEventListener('resize', updateDivWidth);
    };
  }, []);

  return (
    <div className={projectStyles["container"]}>
      <Helmet>
        <title>Oferta - PM Automation - automatyka HVAC</title>
      </Helmet>
      <div className={projectStyles["top-margin"]}/>
      <div className={styles["oferta-container"]}>
        <div className={projectStyles["tytul-container"]}>
            <div className={projectStyles["tytul"]}>
              Oferta
            </div>
            <div className={projectStyles["podtytul"]}>
              Automatyka, której Twoje urządzenie potrzebuje!
            </div>
        </div>
        <div className={projectStyles["tekst"]}>
          <div className={projectStyles["akapit-just"]}>
            Prezentujemy nasze szerokie możliwości w&nbsp;dziedzinie automatyki HVAC. 
            Nasza firma to miejsce, gdzie pasja spotyka się z&nbsp;technologią, 
            tworząc rozwiązania na miarę Twoich potrzeb. Oferujemy kompleksowe usługi, 
            które obejmują projektowanie, instalację oraz serwis zarówno najprostszych,
            jak i&nbsp;zaawansowanych systemów sterowania urządzeniami HVAC.
          </div>
        </div>
      </div>
      <div className={projectStyles["tlo-srednie"]}>
        <div className={projectStyles["max-container"]}>
          <div className={projectStyles["produkty-container"]}>
            <div className={projectStyles["naglowek"]}>
              Dlaczego my?
            </div>
            <div className={projectStyles["produkty"]}>
              <div className={projectStyles["produkt"]} style={{ width: productWidth }}>
                <svg
                  viewBox="0 0 16 16"
                  className={projectStyles["ikona-produktu"]}
                >
                    <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z"/>
                </svg>
                <div className={projectStyles["nazwa-produktu"]}>
                  Doświadczenie
                </div>
                <div className={projectStyles["opis-produktu"]}>
                  10 lat pracy w branży HVAC gwarantuje nam głęboką wiedzę i&nbsp;umiejętności 
                  w&nbsp;obszarze automatyki i&nbsp;układów sterowania.
                </div>
              </div>
              <div className={projectStyles["produkt"]} style={{ width: productWidth }}>
                <svg viewBox="0 0 16 16" className={projectStyles["ikona-produktu"]}>
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.809.809 0 0 1 1.034-.275.809.809 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34c.078.028.16.044.243.054.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.333.333 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501Z"/>
                </svg>
                <div className={projectStyles["nazwa-produktu"]}>
                  Efektywność
                </div>
                <div className={projectStyles["opis-produktu"]}>
                  Nasze rozwiązania nie tylko usprawniają pracę systemów, ale również 
                  przyczyniają się do oszczędności energii.
                </div>
              </div>
              <div className={projectStyles["produkt"]} style={{ width: productWidth }}>
                <svg viewBox="0 0 16 16" className={projectStyles["ikona-produktu"]}>
                  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
                </svg>
                <div className={projectStyles["nazwa-produktu"]}>
                  Personalizacja
                </div>
                <div className={projectStyles["opis-produktu"]}>
                  Każdy projekt traktujemy indywidualnie, zapewniając rozwiązania 
                  dostosowane do Twoich potrzeb.
                </div>
              </div>
              <div className={projectStyles["produkt"]} style={{ width: productWidth }}>
                <svg viewBox="0 0 16 16" className={projectStyles["ikona-produktu"]}>
                  <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z"/>
                </svg>
                <div className={projectStyles["nazwa-produktu"]}>
                  Jakość
                </div>
                <div className={projectStyles["opis-produktu"]}>
                  Używamy sprawdzonych komponentów, 
                  by dostarczyć Ci produkty i&nbsp;usługi spełniające najwyższe standardy.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={projectStyles["fiveunits-margin"]}></div>
      <div className={projectStyles["tytul-container"]}>
            <div className={projectStyles["tytul"]}>
              Obszary działania
            </div>
            <div className={projectStyles["podtytul"]}>
              Czym się zajmujemy?
            </div>
        </div>
      <div className={styles["oferta-container"]}>
        <div className={styles["karta"]}>
          <div className={styles["obraz-karty"]}>
            <img
              alt="rodzielnica"
              loading="lazy"
              src="/playground_assets/mikolow-1500w.png"
              className={styles["grafika"]}
            />
          </div>
          <div className={styles["oferta"]}>
            <div className={projectStyles["podtytul"]}>
              Rozdzielnice
            </div>
            <div className={projectStyles["naglowek"]}>
              Prefabrykacja rozdzielnic sterowniczych
            </div>
            <div className={projectStyles["akapit-just"]}>
              Zajmujemy się profesjonalnym projektowaniem i&nbsp;produkcją
              rozdzielnic zasilająco-sterujących wraz z&nbsp;doborem odpowiedniej
              aparatury elektrycznej i&nbsp;zabezpieczeń. Dobór rozwiązania następuje
              w&nbsp;oparciu o&nbsp;informacje na temat inwestycji, oczekiwania klienta,
              specyfikę układu z&nbsp;nastawieniem na minimalizację kosztów
              inwestycji i&nbsp;eksploatacji przy jednoczesnym łatwym i&nbsp;szybkim
              montażu.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Wykonujemy schematy elektryczne, na podstawie których
              rozdzielnica zostaje zbudowana, a&nbsp;do produkcji wykorzystujemy
              wyłącznie sprawdzone materiały czołowych producentów branży elektrycznej.
              Sercem naszych rozdzielnic zazwyczaj są sterowniki PLC firmy
              Carel, ale do prostszych układów wykorzystujemy też gotowe sterowniki
              firm takich jak: Carel, Eliwell, Danfoss, Ranco, Dixell. Dzięki temu jesteśmy
              w&nbsp;stanie ograniczyć czas potrzebny do zbudowania rozdzielnicy, co przekłada
              się na niższy koszt jej wyprodukowania.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Wytwarzane przez nas szafy zasilająco–sterujące są zgodnie z&nbsp;obowiązującymi 
              normami przy zachowaniu najwyższych standardów jakościowych.
            </div>
          </div>
        </div>
        <div className={styles["karta"]}>
          <div className={styles["obraz-karty"]}>
            <img
              alt="1tool"
              loading="lazy"
              src="/playground_assets/1tool.png"
              className={styles["grafika"]}
            />
          </div>
          <div className={styles["oferta"]}>
            <div className={projectStyles["podtytul"]}>
              Oprogramowanie
            </div>
            <div className={projectStyles["naglowek"]}>
              Aplikacje na sterowniki PLC
            </div>
            <div className={projectStyles["akapit-just"]}>
              Bardziej rozbudowane oraz nietypowe projekty potrzebują
              zaawansowanych rozwiązań. W&nbsp;takiej sytuacji sami tworzymy
              oprogramowanie do sterowników PLC dedykowane do konkretnego
              układu. Pozwala to na precyzyjne i&nbsp;efektywne regulowanie pracą
              wszystkich urządzeń obsługiwanych przez rozdzielnicę sterowniczą
              oraz zapewnia pełny nadzór nad układem. Użytkownik może w&nbsp;dowolnej
              chwili skorygować nastawy, sprawdzić aktualne parametry pracy
              urządzeń czy powiadomienia o&nbsp;alarmach bądź usterkach.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Dzięki własnym aplikacjom na sterowniki PLC jesteśmy w&nbsp;stanie stworzyć
              układ sterujący dowolnym urządzeniem. Nie ma znaczenia czy jest to nowa instalacja,
              czy istniejące już urządzenie, które uległo awarii, a&nbsp;do którego potrzeba stworzyć 
              sterownik. W&nbsp;sytuacji kiedy oryginalny regulator jest niedostępny lub jego cena 
              jest bardzo wysoka, stworzenie własnego sterownika okazuje się idealnym rozwiązaniem.
            </div>
            <div className={projectStyles["akapit-just"]}>
              W zależności od stopnia rozbudowania i&nbsp;specyfiki sterowanych urządzeń
              dobieramy i&nbsp;programujemy sterowniki Carel lub Fatek.
            </div>
          </div>
        </div>
        <div className={styles["karta"]}>
          <div className={styles["obraz-karty"]}>
            <img
              alt="weintek"
              loading="lazy"
              src="/playground_assets/wizualizacja.png"
              className={styles["grafika"]}
            />
          </div>
          <div className={styles["oferta"]}>
            <div className={projectStyles["podtytul"]}>
              Wizualizacje
            </div>
            <div className={projectStyles["naglowek"]}>
              Wizualizacje pracy urządzeń
            </div>
            <div className={projectStyles["akapit-just"]}>
              Dla bardziej wymagających klientów, którzy oprócz funkcjonalności
              cenią sobie prostą i&nbsp;wygodną obsługę, tworzymy wizualizacje pracy
              systemu na panelach dotykowych lub ze zdalnym dostępem z poziomu
              komputera PC, bądź urządzenia mobilnego np. smartfonu czy tabletu.
              Rozwiązania te są bardzo intuicyjne i&nbsp;łatwe w obsłudze gdyż
              wszystkie odczyty czujników i parametry pracy zwizualizowane są na
              schemacie ideowym instalacji.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Zbierane dane mogą być archiwizowane i&nbsp;prezentowane na wykresach,
              bądź gromadzone w plikach w pamięci urządzenia lub na nośniku zewnętrznym. 
              To pozwala z&nbsp;kolei na generowanie dostosowanych do potrzeb klienta
              raportów, a&nbsp;ponadto daje pełny obraz parametrów pracy urządzenia.
            </div>
          </div>
        </div>
        <div className={styles["karta"]}>
          <div className={styles["obraz-karty"]}>
            <img
              alt="monitoring"
              loading="lazy"
              src="./playground_assets/monitoring.png"
              className={styles["grafika"]}
            />
          </div>
          <div className={styles["oferta"]}>
            <div className={projectStyles["podtytul"]}>
              Monitoring urządzeń
            </div>
            <div className={projectStyles["naglowek"]}>
              Zbieranie i&nbsp;archiwizacja danych, tworzenie raportów HACCP
            </div>
            <div className={projectStyles["akapit-just"]}>
              Tworzymy systemy monitoringu urządzeń wraz z&nbsp;raportowaniem na 
              potrzeby systemu HACCP. Podłączamy wszystkie możliwe urządzenia
              znajdujące się na obiekcie do centralnego systemu, z&nbsp;poziomu którego
              użytkownik może nadzorować aktualny stan całego zakładu, przeglądać historię 
              odczytów i&nbsp;awarii, zmieniać nastawy oraz tworzyć całe scenariusze działania 
              instalacji. Ponadto zarchiwizowane dane pozwalają na wygenerowanie raportu 
              temperaturowego z&nbsp;dowolnego zakresu dat oraz o&nbsp;dowolnej częstotliwości odczytu.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Jedną z wykonanych przez nas realizacji było zbudowanie i podłączenie systemu 
              monitorującego w&nbsp;kilkunastu chłodniach rozlokowanych po całym zakładzie.
              Dzięki zainstalowanemu przez nas systemowi użytkownik może generować 
              raporty temperatur HACCP z&nbsp;dowolnego zakresu dat do 2&nbsp;lat wstecz. 
              Dzięki naszemu systemowi papierowe arkusze z odczytami poszły w&nbsp;niepamięć  
              -&nbsp;to oszczędność czasu i&nbsp;pieniędzy.
            </div>
          </div>
        </div>
        <div className={styles["karta"]}>
          <div className={styles["obraz-karty"]}>
            <img
              alt="praca naprzemienna"
              loading="lazy"
              src="/playground_assets/1691864441876.png"
              className={styles["grafika"]}
            />
          </div>
          <div className={styles["oferta"]}>
            <div className={projectStyles["podtytul"]}>
              Mniejsze układy
            </div>
            <div className={projectStyles["naglowek"]}>
              Prefabrykacja drobnych układów sterujących
            </div>
            <div className={projectStyles["akapit-just"]}>
              Oprócz dużych rozdzielnic realizujących logikę pracy całych instalacji 
              zajmujemy się również projektowaniem i&nbsp;produkcją mniejszych układów 
              sterujących. Tworzymy projekty rozdzielnic sterujących pojedynczymi
              komorami chłodniczymi, meblami, zaworami, kotłami, pompami, hydroforami 
              itd. Szereg urządzeń, do których możemy stworzyć sterowanie jest 
              praktycznie nieskończony.
            </div>
            <div className={projectStyles["akapit-just"]}>Wykonujemy moduły pracy 
            naprzemiennej dla klimatyzatorów ulokowanych np. w&nbsp;serwerowniach, gdzie
            ze względu na zainstalowane serwery wymagana jest redundancja układów 
            klimatyzacyjnych. Mamy rozwiązania, które zostały przetestowane na 
            wielu ważnych obiektach takich jak urzędy, kompleksy sportowe, biblioteki, itd. 
            </div>
            <div className={projectStyles["akapit-just"]}>
              Do każdego układu sterowania dołączamy komplet dokumentacji, wraz 
              ze schematem elektrycznym.
            </div>
          </div>
        </div>
        <div className={styles["karta"]}>
          <div className={styles["obraz-karty"]}>
            <img
              alt="montaz"
              loading="lazy"
              src="./playground_assets/1685528024019.png"
              className={styles["grafika"]}
            />
          </div>
          <div className={styles["oferta"]}>
            <div className={projectStyles["podtytul"]}>
              Montaż i&nbsp;uruchomienie
            </div>
            <div className={projectStyles["naglowek"]}>
              Montaż, okablowanie i&nbsp;uruchomienie urządzeń
            </div>
            <div className={projectStyles["akapit-just"]}>
              Oprócz tworzenia i&nbsp;montowania własnych systemów sterowania
              urządzeniami zajmujemy się również montażem i uruchomieniami 
              układów sterujących innych producentów.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Wykonujemy montaże tras kablowych, prowadzenie przewodów, 
              montaż elementów wykonawczych, montaż czujników i&nbsp;zabezpieczeń, 
              montaż rozdzielnic sterowniczych oraz wszystkich niezbędnych 
              do działania urządzenia elementów układu sterującego.
            </div>
            <div className={projectStyles["akapit-just"]}>
              W razie konieczności nie boimy się adaptacji istniejących 
              układów do nietypowego działania, bądź zmian wynikających 
              z&nbsp;innego sposobu użytkowania czy wymiany podzespołów. 
              Specjalizujemy się we wszystkich układach sterujących instalacjami 
              takimi jak centrale wentylacyjne, kotłownie, węzły C.O., agregaty 
              wody lodowej (chillery), systemy bezpieczeństwa gazowego (typu
              GAZEX), itp. Wykonujemy nie tylko uruchomienia i&nbsp;konfiguracje, ale
              też dostrajanie, pomiary i&nbsp;kontrole pracy już działających urządzeń.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Zamontowaliśmy i&nbsp;uruchomiliśmy kilkadziesiąt rozdzielnic do central 
              wentylacyjnych firm takich jak VTS, Clima Gold, Klimor, Vents, Ciecholewski, 
              Swegon, VBW, Frapol, Komfovent, itd.
            </div>
          </div>
        </div>
        <div className={styles["karta"]}>
          <div className={styles["obraz-karty"]}>
            <img
              alt="serwis"
              loading="lazy"
              src="./playground_assets/1691864441860.png"
              className={styles["grafika"]}
            />
          </div>
          <div className={styles["oferta"]}>
            <div className={projectStyles["podtytul"]}>
              Serwis
            </div>
            <div className={projectStyles["naglowek"]}>
              Serwis urządzeń i&nbsp;układów sterujących
            </div>
            <div className={projectStyles["akapit-just"]}>
              Zakres naszej działalności nie kończy się na produkcji i&nbsp;uruchomieniach. 
              Świadczymy oprócz tego usługi polegające na diagnostyce usterek i&nbsp;awarii 
              urządzeń grzewczych, chłodzących, wentylacyjnych itp. 
              Dzięki zdobytemu doświadczeniu jesteśmy w stanie zdiagnozować i&nbsp;usunąć
              usterki, jak również wykryć nieprawidłowości w&nbsp;pracy bądź konfiguracji 
              urządzeń. Nie są nam obce rozbudowane systemy VRV/VRF. Szeroka wiedza na temat 
              działania układów sterujących pozwala na precyzyjne doregulowanie nieprawidłowo 
              bądź nieefektywnie działających urządzeń oraz na sprawne zdiagnozowanie
              i&nbsp;usunięcie usterek i&nbsp;awarii.
            </div>
            <div className={projectStyles["akapit-just"]}>
              W trudnych przypadkach dysponujemy wiedzą i&nbsp;umiejętnościami, które 
              pozwalają nam na przykład zastąpić fabryczny sterownik, który uległ uszkodzeniu 
              własnym urządzeniem, stworzonym na miarę potrzeb.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Naprawiliśmy już cały szereg urządzeń, z&nbsp;którymi inne firmy nie były 
              w&nbsp;stanie sobie poradzić. Uchroniliśmy kilku klientów przed wydaniem 
              znacznych kwot na nieprawidłowo zdiagnozowane naprawy.
            </div>
          </div>
        </div>
        <div className={styles["karta"]}>
          <div className={styles["obraz-karty"]}>
            <img
              alt="przeglady"
              loading="lazy"
              src="./playground_assets/1692435288811.png"
              className={styles["grafika"]}
            />
          </div>
          <div className={styles["oferta"]}>
            <div className={projectStyles["podtytul"]}>
              Przeglądy
            </div>
            <div className={projectStyles["naglowek"]}>
              Przeglądy central wentylacyjnych
            </div>
            <div className={projectStyles["akapit-just"]}>
              Wykonujemy również okresowe przeglądy central wentylacyjnych polegające na
              wymianie filtrów, czyszczeniu i&nbsp;myciu urządzenia oraz kontroli
              wszystkich elementów mechanicznych i&nbsp;elektrycznych. Sprawdzone zostaje 
              działanie elementów wykonawczych oraz nastaw sterownika. Wykonujemy 
              kalibrację przetworników i&nbsp;czujników temperatury.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Większość producentów central wymaga wykonywania przeglądów co pół
              roku - jest to konieczny warunek utrzymania gwarancji na
              urządzenie. Dodatkowo regularne przeglądy pozwalają zapobiec
              większości usterek i&nbsp;awarii urządzenia skutkujących wyłączeniem 
              z&nbsp;pracy, a&nbsp;tym samym zmniejszeniem komfortu osób przebywających 
              w&nbsp;obsługiwanych przez urządzenie pomieszczeniach.
            </div>
          </div>
        </div>
      </div>
      <div className={projectStyles["tlo-srednie"]}>
        <div className={projectStyles["max-container"]}>
          <div className={projectStyles["naglowek"]}>
            Etapy współpracy
          </div>
          <div className={styles["etapy"]}>
            <div className={styles["etap"]}>
              <div className={styles["ikona-container"]}>
                <svg viewBox="0 0 16 16" className={styles["ikona"]}>
                  <path d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z"/>
                </svg>
              </div>
              <div className={styles["etap-opis"]}>
                <div className={styles["etap-naglowek"]}>1. Zapytanie</div>
                <div className={projectStyles["akapit-just"]}>
                  Skontaktuj się z nami i&nbsp;przekaż nam informacje na temat
                  inwestycji oraz swoje oczekiwania.
                </div>
              </div>
            </div>
            <div className={styles["etap"]}>
              <div className={styles["ikona-container"]}>
                <svg viewBox="0 0 16 16" className={styles["ikona"]}>
                  <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                </svg>
              </div>
              <div className={styles["etap-opis"]}>
                <div className={styles["etap-naglowek"]}>2. Oferta</div>
                <div className={projectStyles["akapit-just"]}>
                  Dobierzemy najbardziej optymalne rozwiązanie i&nbsp;przedstawimy
                  ofertę na jego wykonanie oraz termin realizacji.
                </div>
              </div>
            </div>
            <div className={styles["etap"]}>
              <div className={styles["ikona-container"]}>
                <svg viewBox="0 0 16 16" className={styles["ikona"]}>
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                  <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                </svg>
              </div>
              <div className={styles["etap-opis"]}>
                <div className={styles["etap-naglowek"]}>3. Realizacja</div>
                <div className={projectStyles["akapit-just"]}>
                  Wykonamy projekt, skompletujemy niezbędne materiały 
                  i&nbsp;stworzymy kompletną rozdzielnicę zasilająco sterującą 
                  wraz z&nbsp;oprogramowaniem.
                </div>
              </div>
            </div>
            <div className={styles["etap"]}>
              <div className={styles["ikona-container"]}>
                <svg viewBox="0 0 16 16" className={styles["ikona"]}>
                  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
                </svg>
              </div>
              <div className={styles["etap-opis"]}>
                <div className={styles["etap-naglowek"]}>4. Wsparcie</div>
                <div className={projectStyles["akapit-just"]}>
                  Zapewniamy wsparcie w samodzielnym montażu i&nbsp;rozruchu układu
                  lub montujemy i&nbsp;uruchamiamy układ na życzenie.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Oferta;