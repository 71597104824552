import React, {useState} from "react";
import { Link } from "react-router-dom";

import projectStyles from "../style.module.css";

function Stopka() {

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    return (
        <>
            <div className={projectStyles["section-separator"]}></div>
            <div id="stopka" className={projectStyles["footer-container"]}>
                <div className={projectStyles["footer"]}>
                    <div className={projectStyles["social-links"]}>
                        <Link
                            to="/polityka"
                            className={projectStyles["footer-link"]}
                        >
                            <svg viewBox="0 0 16 16" className={projectStyles["footer-ikona"]}>
                                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                            </svg>
                        </Link>
                        <a
                            href="https://goo.gl/maps/GqKnAyktZEqXRv3G6"
                            target="_blank"
                            rel="noreferrer noopener"
                            className={projectStyles["footer-link"]}
                        >
                            <svg viewBox="0 0 16 16" className={projectStyles["footer-ikona"]}>
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                        </a>
                        <a
                            href="tel:+48726377475"
                            className={projectStyles["footer-link"]}
                        >
                            <svg viewBox="0 0 16 16" className={projectStyles["telefon-ikona"]}>
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                            </svg>
                        </a>
                        <a
                            href="https://facebook.com/automatykahvac"
                            target="_blank"
                            className={projectStyles["footer-link"]}
                        >
                            <svg viewBox="0 0 16 16" className={projectStyles["footer-ikona"]}>
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                            </svg>
                        </a>
                    </div>
                    <a
                        href="https://rzetelnafirma.pl/GH4500C7"
                        className={projectStyles["footer-link"]}
                        target="_blank"
                    >
                        <img
                            alt="rzetelna_firma"
                            src="./playground_assets/rzetelna_mini.svg"
                            className={projectStyles["rzetelna-ikona"]} />
                    </a>
                    <div className={projectStyles["copyright"]}>
                        <span
                            className={` ${projectStyles["anchor"]} ${projectStyles["pmautomation"]} `}
                        >
                            &copy; Copyright PM Automation, {currentYear}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Stopka;