import React from "react";
import { Helmet } from "react-helmet";

import projectStyles from "../style.module.css";
import styles from "./polityka.module.css";

function Polityka() {
  return (
    <div className={projectStyles["container"]}>
      <Helmet>
        <title>Polityka prywatności - PM Automation - automatyka HVAC</title>
      </Helmet>/
      <div className={styles["polityka-container"]}>
        <div className={styles["polityka"]}>
          <div className={styles["opis"]}>
            <div className={projectStyles["tytul"]}>
              Polityka prywatności i&nbsp;plików cookies
            </div>

            <div className={projectStyles["podtytul"]}>
              I. POSTANOWIENIA OGÓLNE
            </div>

            <div className={projectStyles["akapit-just"]}>
              <ol>
                <li>
                  Niniejsza Polityka prywatności określa zasady przetwarzania
                  danych osobowych użytkowników serwisu internetowego w&nbsp;domenie
                  https://www.pmautomation.pl/ (zwanym dalej dalej Serwis).
                </li>
                <li>
                  Aministratorem danych osobowych zbieranych za pośrednictwem
                  Serwisu jest PM Automation - automatyka HVAC Paweł Malcharek
                  (dalej: Administrator).
                </li>
                <li>
                  Administrator przetwarza dane osobowe zgodnie z
                  Rozporządzeniem Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679
                  z&nbsp;dnia 27 kwietnia 2016 r. w&nbsp;sprawie ochrony osób fizycznych 
                  w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w sprawie
                  swobodnego przepływu takich danych oraz uchylenia dyrektywy
                  95/46/WE (dalej: RODO/GDPR).
                </li>
                <li>
                  Administrator dokłada szczególnej staranności w&nbsp;celu ochrony
                  interesów osób, których dane dotyczą, a&nbsp;w szczególności
                  zapewnia, że zbierane przez niego dane są:
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>przetwarzane zgodnie z&nbsp;prawem,</li>
                    <li>
                      zbierane dla oznaczonych, zgodnych z&nbsp;prawem celów 
                      i&nbsp;niepoddawane dalszemu przetwarzaniu niezgodnemu z&nbsp;tymi
                      celami,
                    </li>
                    <li>
                      merytorycznie poprawne i&nbsp;adekwatne w&nbsp;stosunku do celów, 
                      w&nbsp;jakich są przetwarzane,
                    </li>
                    <li>
                      przechowywane w&nbsp;postaci umożliwiającej identyfikację osób,
                      których dotyczą, nie dłużej niż jest to niezbędne do
                      osiągnięcia celu przetwarzania.
                    </li>
                  </ol>
                </li>
                <li>
                  Gromadzone dane osobowe nie są wykorzystywane przez
                  Administratora do podejmowania zautomatyzowanych decyzji, 
                  w&nbsp;tym do profilowania.
                </li>
              </ol>
            </div>

            <div className={projectStyles["podtytul"]}>
              II. ZAKRES DANYCH OSOBOWYCH, CELE, PODSTAWY PRAWNE I&nbsp;CZAS
              PRZETWARZANIA DANYCH OSOBOWYCH
            </div>

            <div className={projectStyles["akapit-just"]}>
              <ol>
                <li>
                  Administrator przetwarza Państwa dane osobowe w&nbsp;ściśle
                  określonym zakresie i&nbsp;jasno sprecyzowanych celach, w&nbsp;tym do
                  poprawy funkcjonalności Serwisu i&nbsp;dostosowywania ich do
                  Państwa indywidualnych oczekiwań i&nbsp;preferencji – podstawą
                  prawną przetwarzania danych jest Państwa świadoma i&nbsp;dobrowolna
                  zgoda wyrażona w&nbsp;zakresie gromadzenia plików cookie (art. 6
                  ust. 1 lit.&nbsp;a RODO) – w&nbsp;takiej sytuacji dane przechowywane są
                  przez Administratora na zasadach opisanych w&nbsp;rozdziale V
                  niniejszego dokumentu (Pliki cookie).
                </li>
                <li>
                  Administrator przetwarza Państwa dane osobowe, w&nbsp;minimalnym
                  zakresie niezbędnym do realizacji powyższych celów, w&nbsp;tym dane
                  zapisane w&nbsp;plikach cookie oraz inne dane podane przez Państwa
                  świadomie w&nbsp;kierowanej do Administratora wiadomości.
                </li>
                <li>
                  Podanie danych osobowych jest dobrowolne, jednak niezbędne do
                  realizacji powyższych celów.
                </li>
              </ol>
            </div>

            <div className={projectStyles["podtytul"]}>
              III. ODBIORCY DANYCH
            </div>

            <div className={projectStyles["akapit-just"]}>
              <ol>
                <li>
                  Państwa dane osobowe mogą być przekazywane jedynie
                  podwykonawcom współpracującym z&nbsp;Administratorem, w&nbsp;zakresie
                  utrzymania i&nbsp;rozwoju systemów informatycznych niezbędnych do
                  obsługi Serwisu, jak również w&nbsp;określonych przypadkach
                  podmiotom świadczącym usługi kuriersko-pocztowe, księgowe,
                  windykacyjne, prawne i&nbsp;reklamowe.
                </li>
                <li>
                  Państwa dane osobowe gromadzone za pośrednictwem plików cookie
                  mogą być przekazywane do zewnętrznych operatorów, w&nbsp;takiej
                  sytuacji Administrator zaleca zapoznanie się z&nbsp;informacjami na
                  temat przetwarzania danych osobowych dostępnych na stronach
                  tych operatorów.
                </li>
                <li>
                  Państwa dane osobowe, co do zasady nie są przekazywane do
                  państw trzecich i&nbsp;organizacji międzynarodowych.
                </li>
              </ol>
            </div>

            <div className={projectStyles["podtytul"]}>
              IV. PRZYSŁUGUJĄCE PRAWA
            </div>

            <div className={projectStyles["akapit-just"]}>
              <ol>
                <li>
                  W związku z&nbsp;przetwarzaniem przez Administratora danych
                  osobowych, przysługują Państwu następujące prawa:
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      prawo dostępu do własnych danych; tj. prawo do uzyskania
                      informacji jakie kategorie Państwa danych osobowych
                      posiada Administrator,
                    </li>
                    <li>
                      prawo do sprostowania danych tj. do ich poprawy lub
                      aktualizacji,
                    </li>
                    <li>
                      prawo do usunięcia danych w&nbsp;sytuacji, kiedy nie są one
                      niezbędne do realizacji celu, dla którego zostały zebrane,
                    </li>
                    <li>prawo do ograniczenia przetwarzania danych,</li>
                    <li>
                      prawo do przenoszenia danych tj. prawo do uzyskania kopii
                      danych osobowych i&nbsp;przekazania ich do innego
                      administratora na Państwa wyraźną prośbę,
                    </li>
                  </ol>
                </li>
                <li>
                  Jeżeli uznacie Państwo, że przetwarzanie przez Administratora
                  Państwa danych osobowych narusza przepisy RODO i&nbsp;tym samym
                  Państwa prawa lub wolności, wówczas przysługuje Państwu prawo
                  wniesienia skargi do Urzędu Ochrony Danych Osobowych.
                </li>
              </ol>
            </div>

            <div className={projectStyles["podtytul"]}>V. PLIKI COOKIE</div>

            <div className={projectStyles["akapit-just"]}>
              <ol>
                <li>
                  Serwis korzysta z&nbsp;informacji zapisywanych za pomocą plików
                  cookie, czyli danych informatycznych przechowywanych 
                  w&nbsp;urządzeniach końcowych użytkowników wykorzystywanych do
                  korzystania z&nbsp;Serwisu.
                </li>
                <li>
                  Gromadzone pliki cookie pozwalają w&nbsp;szczególności rozpoznać
                  Państwa urządzenie i&nbsp;odpowiednio wyświetlać stronę internetową
                  Serwisu dostosowaną do Państwa indywidulanych preferencji.
                  Pliki te pozwalają także na tworzenie przez Administratora
                  anonimowych statystyk dotyczących ruchu na stronie.
                </li>
                <li>
                  Serwis wykorzystuje następujące pliki cookie:
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      Niezbędne: konieczne do prawidłowego funkcjonowania Serwisu przez <b>30 dni</b>
                    </li>
                    <li>Statystyczne: pozwalają Administratorowi badać ruch na stronie
                        internetowej Serwisu oraz zapewnić bezpieczeństwo
                        korzystania z&nbsp;niej przez <b>2 lata</b>
                    </li>
                  </ol>
                </li>
                <li>
                  Serwis wykorzystuje także tzw. pliki cookie zewnętrzne, tj.
                  pliki innych operatorów, w&nbsp;tym współpracującym 
                  z&nbsp;Administratorem reklamodawcom i&nbsp;innym partnerom.
                </li>
                <li>
                  Standardowo powszechnie wykorzystywane przeglądarki
                  internetowe domyślnie akceptują zapisywanie wszystkich plików
                  cookie, jednak ich operatorzy zapewniają możliwość zarządzania
                  ustawieniami związanymi z&nbsp;gromadzeniem plików cookie, w&nbsp;tym
                  umożliwiają częściowe ograniczenie lub całkowite wyłączenie
                  możliwości zapisywania plików cookie.
                </li>
                <li>
                  Administrator informuje, że całkowite wyłączenie zapisywania
                  plików cookie może niekorzystnie wpłynąć na wybrane funkcje
                  Serwisu.
                </li>
                <li>
                  Szczegółowe informacje i&nbsp;zarządzania ustawieniami plików 
                  w&nbsp;powszechnie wykorzystywanych przeglądarkach internetowych
                  dostępne są pod poniższymi adresami:
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      Internet Explorer™ -
                      <a href="https://support.microsoft.com/pl-pl/help/278835/">
                        {" "}
                        link do strony,
                      </a>
                    </li>
                    <li>
                      Mozilla Firefox™ -
                      <a href="https://support.mozilla.org/pl/kb/usuwanie-ciasteczek">
                        {" "}
                        link do strony,
                      </a>
                    </li>
                    <li>
                      Chrome™ -
                      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl">
                        {" "}
                        link do strony,
                      </a>
                    </li>
                    <li>
                      Opera™ -
                      <a href="https://www.opera.com/help/tutorials/security/cookies/">
                        {" "}
                        link do strony,
                      </a>
                    </li>
                    <li>
                      Safari™ -
                      <a href="https://support.apple.com/pl-pl/HT201265">
                        {" "}
                        link do strony,
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>

            <div className={projectStyles["podtytul"]}>
              VI. POSTANOWIENIA KOŃCOWE
            </div>

            <div className={projectStyles["akapit-just"]}>
              <ol>
                <li>
                  Administrator zastrzega sobie prawo do wprowadzania zmian 
                  w&nbsp;Polityce prywatności, jednocześnie zapewnia jednak, że Państwa
                  prawa wynikające z&nbsp;niniejszej Polityki nie zostaną w&nbsp;żaden
                  sposób ograniczone bez Państwa zgody.
                </li>
                <li>
                  Dane kontaktowe do Administratora: PM&nbsp;Automation - automatyka
                  HVAC Paweł Malcharek, ul. Orla&nbsp;12, 43-210 Kobiór.
                </li>
                <li>
                  Dane kontaktowe osoby odpowiedzialnej za ochronę danych
                  osobowych: Paweł Malcharek, e-mail: biuro@pmautomation.pl.
                </li>
                <li>
                  W przypadku jakichkolwiek wątpliwości jak również z&nbsp;wnioskami
                  dotyczącymi woli skorzystania z&nbsp;przysługujących Państwu praw,
                  prosimy o&nbsp;kontakt z&nbsp;osobą odpowiedzialną za ochronę danych
                  osobowych na powyższy adres.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <img
            className={styles["logoduze"]}
            alt="Logo"
            src="./playground_assets/logodb-1500h.png"
          />
      </div>
    </div>
  );
}

export default Polityka;
