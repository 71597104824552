import React from "react";
import { Link, useLocation } from "react-router-dom";

import projectStyles from "../style.module.css";

function Menu() {

  // Funkcja sprawdzająca aktualną ścieżkę
  const location = useLocation()
  var sciezka = location.pathname

  // Funkcja sprawdzająca, czy dana ścieżka jest aktywna
  const isPathActive = (path) => {
    return sciezka === path;
  };
  
  return (
      <>
      <div id="burger-menu" className={projectStyles["burger-menu"]}>
          <div className={projectStyles["burger-container"]}>
              <div className={projectStyles["menu-container"]}>
                  <Link
                      to="/"
                      className={projectStyles["navlink"]}
                      
                  >
                    <div className={projectStyles["menu-option"]}>
                      <svg
                        viewBox="0 0 16 16"
                        className={
                          isPathActive('/')
                            ? projectStyles["icon-active"]
                            : projectStyles["icon"]
                      }>
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
                      </svg>
                      <span
                        className={
                          isPathActive('/')
                            ? projectStyles["text-active"]
                            : projectStyles["text"]
                      }>
                        Home
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="/o_firmie"
                    className={projectStyles["navlink"]}
                  >
                      <div className={projectStyles["menu-option"]}>
                        <svg
                          viewBox="0 0 16 16"
                          className={
                            isPathActive('/o_firmie')
                              ? projectStyles["icon-active"]
                              : projectStyles["icon"]
                        }>
                          <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                        </svg>
                        <span
                          className={
                            isPathActive('/o_firmie')
                              ? projectStyles["text-active"]
                              : projectStyles["text"]
                          }
                        >
                          O firmie
                        </span>
                      </div>
                  </Link>
                  <Link
                    to="/oferta"
                    className={projectStyles["navlink"]}
                  >
                      <div className={projectStyles["menu-option"]}>
                        <svg
                          viewBox="0 0 16 16"
                          className={
                            isPathActive('/oferta')
                              ? projectStyles["icon-active"]
                              : projectStyles["icon"]
                        }>
                          <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                        <span
                          className={
                            isPathActive('/oferta')
                              ? projectStyles["text-active"]
                              : projectStyles["text"]
                          }
                        >
                          Oferta
                        </span>
                      </div>
                  </Link>
                  <Link
                    to="/realizacje"
                    className={projectStyles["navlink"]}
                  >
                    <div className={projectStyles["menu-option"]}>
                      <svg
                        viewBox="0 0 16 16"
                        className={
                          isPathActive('/realizacje')
                            ? projectStyles["icon-active"]
                            : projectStyles["icon"]
                      }>
                        <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                      </svg>
                      <span
                        className={
                          isPathActive('/realizacje')
                            ? projectStyles["text-active"]
                            : projectStyles["text"]
                        }
                      >
                        Realizacje
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="/kontakt"
                    className={projectStyles["navlink"]}
                  >
                    <div className={projectStyles["menu-option"]}>
                      <svg
                        viewBox="0 0 16 16"
                        className={
                          isPathActive('/kontakt')
                            ? projectStyles["icon-active"]
                            : projectStyles["icon"]
                      }>
                       <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                      </svg>
                      <span
                        className={
                          isPathActive('/kontakt')
                            ? projectStyles["text-active"]
                            : projectStyles["text"]
                        }>
                        Kontakt
                      </span>
                    </div>
                  </Link>
              </div>
          </div>
      </div>
      <div id="navbar" className={projectStyles["navbar-container"]}>
              <div className={projectStyles["navbar"]}>
                  <Link
                    to="/"
                    className={projectStyles["logolink"]}
                  >
                    <img
                      alt="logo"
                      src="/playground_assets/logomb-200h.png"
                      className={projectStyles["pm-logo"]}
                    />
                  </Link>
                  <div className={projectStyles["links-container"]}>
                      <Link
                        to="/"
                        className={
                          isPathActive('/')
                            ? projectStyles["link-active"]
                            : projectStyles["link"]
                        }
                      >
                        Home
                      </Link>
                      <Link
                        to="/o_firmie"
                        className={
                          isPathActive('/o_firmie')
                            ? projectStyles["link-active"]
                            : projectStyles["link"]
                        }
                      >
                        O firmie
                      </Link>
                      <Link
                        to="/oferta"
                        className={
                          isPathActive('/oferta')
                            ? projectStyles["link-active"]
                            : projectStyles["link"]
                        }
                      >
                        Oferta
                      </Link>
                      <Link
                        to="/realizacje"
                        className={
                          isPathActive('/realizacje')
                            ? projectStyles["link-active"]
                            : projectStyles["link"]
                        }
                      >
                        Realizacje
                      </Link>
                      <Link
                        to="/kontakt"
                        className={
                          isPathActive('/kontakt')
                            ? projectStyles["linkkoncowy-active"]
                            : projectStyles["linkkoncowy"]
                        }
                      >
                        Kontakt
                      </Link>
                  </div>
              </div>
          </div>
      </>
  );
}

export default Menu;