import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Modal from "./modal.js";

import projectStyles from "../style.module.css";
import styles from "./o_firmie.module.css";

function OFirmie() {
  const [show, setShow] = useState(false);

  function otworz() {
    setShow(true);
  }

  return (
    <div className={projectStyles["container"]}>
      <Helmet>
        <title>O firmie - PM Automation - automatyka HVAC</title>
      </Helmet>
      <div className={projectStyles["top-margin"]}/>
      <div className={styles["o-firmie-container"]}>
        <div className={projectStyles["tytul-container"]}>
            <div className={projectStyles["tytul"]}>
              PM Automation
            </div>
            <div className={projectStyles["podtytul"]}>
              Precyzja regulacji i&nbsp;niezawodność Twoich urządzeń
            </div>
          </div>
        <div className={styles["o-firmie"]}>
          <div className={styles["logoduze"]}> 
            <img
              alt="logo"
              loading="lazy"
              src="/playground_assets/logodb-1500h.png"
              className={styles["logo-obraz"]}
            />
          </div>
          <div className={styles["opis"]}>
            <div className={styles["tekst"]}>
              <div className={projectStyles["pogrubiony"]}>
               Witamy w&nbsp;PM&nbsp;Automation - firmie specjalizującej się w&nbsp;automatyce HVAC.
              </div>
              <span className={projectStyles["akapit-just"]}>
                Historia naszej firmy rozpoczęła się, kiedy zaczęliśmy tworzyć rozwiązania automatyki według własnych standardów.
                Dzięki głębokiemu zrozumieniu zasad i&nbsp;specyfiki działania urządzeń HVAC oraz pragnieniu tworzenia precyzyjnych i&nbsp;niezawodnych rozwiązań, 
                PM Automation narodziło się jako marka, która wyróżnia się na rynku.
              </span>
              <span className={projectStyles["akapit-just"]}>
                Od momentu powstania, nasza firma tworzy zaawansowane systemy sterowania urządzeniami HVAC.
                Nasza działalność opiera się na wieloletnim doświadczeniu w&nbsp;zakresie serwisu, montażu i&nbsp;projektowania automatyki.
                Kierujemy się wartościami uczciwości, lojalności i&nbsp;dbałości o&nbsp;interes klienta.
                Dzięki naszemu doświadczeniu oraz indywidualnemu podejściu projektowemu, tworzymy precyzyjne i&nbsp;niezawodne rozwiązania, 
                które spełniają najwyższe standardy jakościowe i&nbsp;oczekiwania klienta.
              </span>
            </div>
          </div>
        </div>
        <div className={styles["o-firmie"]}>
           <div className={styles["logoduze"]}> 
            <a
              href="https://rzetelnafirma.pl/GH4500C7"
              target="_blank"
              className={styles["logo-obraz"]}>
              <img
                alt="rzetelna_firma"
                src="./playground_assets/rzetelna_firma.svg"
              />
            </a>
          </div>
          <div className={styles["opis"]}>
            <div className={styles["tekst"]}>
              <span className={projectStyles["akapit-just"]}>
              Stawiamy wyłącznie na komponenty renomowanych marek, aby zapewnić niezawodność pracy i&nbsp;trwałość naszych rozwiązań.
              Wiarygodność naszej firmy potwierdzona jest udziałem w&nbsp;programie "Rzetelna Firma". Sprawdź certyfikat klikając w&nbsp;logo programu.
              </span>
              <span className={projectStyles["akapit-just"]}>
              Wykonaliśmy dziesiątki zaawansowanych systemów sterowania chłodnictwem przemysłowym, 
              regulujemy pracą wielu kompleksowych instalacji grzewczych i&nbsp;odzysków ciepła oraz
              stworzyliśmy kilkanaście rozbudowanych systemów centralnego sterowania BMS. 
              Na naszym oprogramowaniu działa kilkadziesiąt central wentylacyjnych w&nbsp;restauracjach, obiektach sportowych, rekreacyjnych, 
              w&nbsp;szkołach, na basenach i&nbsp;innych obiektach użyteczności publicznej.
              </span>
            </div>
          </div>
        </div>
        <div className={styles["o-firmie"]}>
          <div className={styles["opis"]}>
            <div className={styles["tekst"]}>
              <span className={projectStyles["akapit-just"]}>
              Zawiązaliśmy stałą współpracę z&nbsp;kilkoma dużymi, lokalnymi firmami branżowymi. Dzięki temu ilość wprowadzonych na rynek układów sterowania 
              stale rośnie. Pozwala nam to na ciągły rozwój i&nbsp;doskonalenie naszych rozwiązań.
              Działamy głownie na obszarze Śląska i&nbsp;okolicznych województw, ale jesteśmy gotowi podjąć się projektów 
              na terenie całej Polski, jak również poza granicami kraju.
              </span>
              <span className={projectStyles["akapit-just"]}>
              Jeśli Twoje urządzenie lub Twoja instalacja potrzebuje systemu sterowania stworzonego według najwyższych standardów, 
              to PM Automation jest idealnym partnerem dla Ciebie. Skontaktuj się z&nbsp;nami już dziś i&nbsp;przekonaj się, 
              jak możemy pomóc w&nbsp;realizacji Twoich potrzeb.
              </span>
            </div>
          </div>
        </div>
        <div className={styles["wspolpraca"]}>
          <div className={styles["zapytania"]}>
              <div className={projectStyles["naglowek"]}>WSPÓŁPRACA</div>
                <div className={projectStyles["akapit-just"]}>
                  Jeśli interesuje Cię współpraca z&nbsp;nami - odezwij się.
                  Skorzystaj z&nbsp;jednej z&nbsp;form kontaktu:
                </div>
                <div className={styles["adres"]}>
                  <a
                    href="https://goo.gl/maps/GqKnAyktZEqXRv3G6"
                    target="_blank"
                    rel="noreferrer noopener"
                    title="Zobacz naszą wizytówkę w Google"
                  >
                    <div className={styles["adres-wiersz"]}>
                      <svg viewBox="0 0 16 16" className={styles["ikona"]}>
                        <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z"/>
                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z"/>
                      </svg>
                      <b>PM Automation - automatyka HVAC</b>
                    </div>
                  </a>
                  <a
                    href="mailto:biuro@pmautomation.pl?subject=Zapytanie"
                    title="Napisz do nas maila z zapytaniem."
                  >
                    <div className={styles["adres-wiersz"]}>
                      <svg viewBox="0 0 16 16" className={styles["ikona"]}>
                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                      </svg>
                      biuro@pmautomation.pl
                    </div>
                  </a>
                  <a 
                    href="tel:+48726377475"
                    title="Zadzwoń do nas."
                  >
                    <div className={styles["adres-wiersz"]}>
                      <svg viewBox="0 0 16 16" className={styles["ikona"]}>
                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                      </svg>
                      +48 726 377 475
                    </div>
                  </a>
                  <div
                    onClick={otworz}
                    className={styles["adres-wiersz"]}
                    title="Wyświetl cyfrową wizytówkę."
                  >
                    <svg viewBox="0 0 16 16" className={styles["ikona"]}>
                      <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0v-3Zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5ZM.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5Zm15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5ZM4 4h1v1H4V4Z"/>
                      <path d="M7 2H2v5h5V2ZM3 3h3v3H3V3Zm2 8H4v1h1v-1Z"/>
                      <path d="M7 9H2v5h5V9Zm-4 1h3v3H3v-3Zm8-6h1v1h-1V4Z"/>
                      <path d="M9 2h5v5H9V2Zm1 1v3h3V3h-3ZM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8H8Zm2 2H9V9h1v1Zm4 2h-1v1h-2v1h3v-2Zm-4 2v-1H8v1h2Z"/>
                      <path d="M12 9h2V8h-2v1Z"/>
                    </svg>
                    Paweł Malcharek
                  </div>
                  <Modal
                    content={
                      <img 
                        alt="qrcode"
                        src="./playground_assets/vcard.svg"
                        className={styles["vcard"]}
                      />
                    }
                    onClose={() => setShow(false)}
                    show={show}
                  />
                </div>
                <div className={projectStyles["podtytul"]}>
                  ZAPRASZAMY DO WSPÓŁPRACY!
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OFirmie;