import React from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import styles from "./opinie.module.css";

function Opinie() {
  return (
    <div className={styles["container"]}>
      <ElfsightWidget widgetID="f2a4ea3e-2674-499f-85f2-e85702554644" />
    </div>
  );
}

export default Opinie;
