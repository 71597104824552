import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  withRouter,
  Link
} from 'react-router-dom';
import CookieConsent, {
  getCookieConsentValue,
  visible,
  resetCookieConsentValue
} from "react-cookie-consent";

// Import arkuszy stylów //
import styles from './style.module.css'

// Import stron //
import Oferta from './views/oferta';
import OFirmie from './views/o_firmie';
import Kontakt from './views/kontakt';
import Realizacje from './views/realizacje';
import Polityka from './views/polityka';
import Home from './views/home';
import Tlo from './views/consent'
import Menu from './views/menu'
import Stopka from './views/stopka'
import NotFound from './views/404'


// Aplikacja
function App() {

  //funkcja przewijająca ekran do góry po załadowaniu nowej zawartości strony//
  const ScrollToTop = withRouter(_ScrollToTop);

  function _ScrollToTop(props) {
      const { pathname } = useLocation();
      useEffect(() => {
          window.scrollTo(0, 0);
      }, [pathname]);
      return props.children;
  }

  // Funkcja do pobierania wartości plików cookies
  const consent = getCookieConsentValue("PMAutomation");
  const [cookieEnabled, setCookieEnabled] = useState('false');
  useEffect(() => {
    if (consent === "true") {
      document.body.style.overflow = "scroll";
      setCookieEnabled(true);
    } else {
      document.body.style.overflow = "hidden";
      setCookieEnabled(false);
    }
  }, [consent]);

  return (
    <>
      <div>
        <Router>
            {cookieEnabled ? null : <Tlo />}
            <Menu />
            <ScrollToTop>
              <Switch>
                <Route exact component={Oferta} path="/oferta" />
                <Route exact component={OFirmie} path="/o_firmie" />
                <Route exact component={Kontakt} path="/kontakt" />
                <Route exact component={Realizacje} path="/realizacje" />
                <Route exact component={Polityka} path="/polityka" />
                <Route exact component={Home} path="/" />
                <Route component={NotFound} /> {/* Trasa dla 404 */}
              </Switch>
            </ScrollToTop>
            <CookieConsent //powiadomienie o używaniu plików Cookies//
              location="bottom"
              buttonWrapperClasses={styles["cookie-buttons"]}
              buttonText="Zaakceptuj"
              buttonStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textJustify: "center",
                backgroundColor: "#000000",
                border: "none !important",
                borderRadius: "6px",
                boxShadow: "8px 8px 18px -4px rgba(127, 129, 152, 0,8)",
                marginTop: "8px",
                color: "#ffffff",
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: '600',
                lineHeight: "1,5",
                padding: "10px 20px",
                boxSizing: "border-box",
                transition: "0.3s",
              }}
              declineButtonText="Odrzuć"
              declineButtonStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textJustify: "center",
                backgroundColor: "#c00000",
                border: "none !important",
                borderRadius: "6px",
                boxShadow: "8px 8px 18px -4px rgba(127, 129, 152, 0,8)",
                marginTop: "8px",
                color: "#ffffff",
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: '600',
                lineHeight: "1,5",
                padding: "10px 20px",
                boxSizing: "border-box",
                transition: "0.3s"
              }}
              enableDeclineButton
              onAccept={() => {
                document.body.style.overflow = "auto";
                setCookieEnabled(true);
              } }
              onDecline={() => {
                resetCookieConsentValue("PMAutomation");
                setCookieEnabled(false);
                visible = "show";
                window.location.href = "http://www.google.pl";
              } }
              cookieName="PMAutomation"
              style={{
                //cookies pasek
                display: "flex",
                flex: "auto",
                margin: "0px",
                alignItems: "center",
                background: "#353535",
                opacity: "0.97",
                position: "fixed",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                zIndex: "999",
              }}
              expires={30}
            >

              <div style={{
                //Ramka duża zewnętrzna
                display: "flex",
                flexDirection: "row",
                width: "100%",
                maxWidth: "1414px",
                boxSizing: "border-box",
              }}>
                <div style={{
                  //Ramka z tekstami
                  display: "flex",
                  font: "Arial",
                  color: "white",
                  fontWeight: "600",
                  lineHeight: "normal",
                  fontSize: "20px",
                  flexDirection: "column",
                }}>
                  <div>Szanujemy Twoją prywatność!</div>

                  <div style={{
                    //Ramka z małym tekstem
                    fontSize: "14px",
                    fontWeight: "100",
                    marginTop: "16px",
                  }}>
                    Nasza strona internetowa wykorzystuje pliki cookies w&nbsp;celu zapewnienia
                    komfortu podczas jej przeglądania. Szczegóły dotyczące wykorzystywania
                    plików Cookies znajdują się w&nbsp;naszej&#160; 
                    <Link 
                      to="/polityka"
                      className={styles["polityka"]}
                      onClick={() => {
                      document.body.style.overflow = "auto";
                    } }>
                      polityce prywatności
                    </Link>.
                  </div>
                </div>
              </div>
            </CookieConsent>
            <Stopka />  
        </Router>
      </div>
    </>
    );
}

ReactDOM.render(<App />, document.getElementById('app'));