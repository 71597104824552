import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Carousel } from "react-carousel-minimal";

import projectStyles from "../style.module.css";
import styles from "./home.module.css";

const data = [
  {
    image: "./playground_assets/cpcosistema.jpg",
    caption: "c.pCO Sistema"
  },
  {
    image: "./playground_assets/cpco.jpg",
    caption: "c.pCO"
  },
  {
    image: "./playground_assets/pco5+sistema.jpg",
    caption: "pCO5+ Sistema"
  },
  {
    image: "./playground_assets/pco5sistema.jpg",
    caption: "pCO5 Sistema"
  },
  {
    image: "./playground_assets/pco5.jpg",
    caption: "pCO5"
  },
  {
    image: "./playground_assets/pco51.jpg",
    caption: "pCO5"
  },
  {
    image: "./playground_assets/pco52.jpg",
    caption: "pCO5"
  },
  {
    image: "./playground_assets/pgd.jpg",
    caption: "Panel pGD"
  },
  {
    image: "./playground_assets/pcoweb.jpg",
    caption: "Karta pCOWEB"
  }
];

const captionStyle = {
  fontSize: "20px",
  fontWeight: "500",
  color: "black",
  display: "none"
};
const slideNumberStyle = {
  fontSize: "14px",
  fontWeight: "500",
  color: "black"
};

const Home = () => {

  return (
    <div className={projectStyles["container"]}>
      <Helmet>
        <title>PM Automation - automatyka HVAC</title>
        <link rel="canonical" href="https://pmautomation.pl" />
      </Helmet>
      <div className={projectStyles["top-margin"]}/>
      <div className={projectStyles["tlo-jasne"]}>
        <div className={styles["powitanie-container"]}>
          <div className={styles["powitanie"]}>
            <div className={projectStyles["tytul"]}>Automatyka HVAC/R</div>
            <div className={projectStyles["podtytul"]}>PM Automation</div>
            <div className={projectStyles["pogrubiony"]}>
              Witamy na naszej stronie internetowej!
            </div>
            <div className={projectStyles["akapit-just"]}>
              Zajmujemy się projektowaniem, produkcją oraz montażem i&nbsp;serwisem
              nowoczesnych systemów sterujących dla branż takich jak:
            </div>
            <div className={styles["branze"]}>
              <div className={styles["branza"]}>
                <svg viewBox="0 0 16 16" className={styles["ikona-branza"]}>
                  <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z"/>
                </svg>
                Wentylacja
              </div>
              <div className={styles["branza"]}>
                <svg
                  viewBox="0 0 16 16"
                  className={styles["ikona-branza"]}
                >
                  <path d="M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793v-1.086l-.646.647a.5.5 0 0 1-.707-.708L7.5 10.293V8.866l-1.236.713-.495 1.85a.5.5 0 1 1-.966-.26l.237-.882-.94.542-.496 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.884-.237a.5.5 0 1 1 .26-.966l1.848.495.94-.542-.882-.237a.5.5 0 1 1 .258-.966l1.85.495L7 8l-1.236-.713-1.849.495a.5.5 0 1 1-.258-.966l.883-.237-.94-.542-1.85.495a.5.5 0 0 1-.258-.966l.883-.237-1.12-.646a.5.5 0 1 1 .5-.866l1.12.646-.237-.883a.5.5 0 0 1 .966-.258l.495 1.849.94.542-.236-.883a.5.5 0 0 1 .966-.258l.495 1.849 1.236.713V5.707L6.147 4.354a.5.5 0 1 1 .707-.708l.646.647V3.207L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 0 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v1.086l.647-.647a.5.5 0 1 1 .707.708L8.5 5.707v1.427l1.236-.713.495-1.85a.5.5 0 1 1 .966.26l-.236.882.94-.542.495-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495-.94.542.883.237a.5.5 0 1 1-.26.966l-1.848-.495L9 8l1.236.713 1.849-.495a.5.5 0 0 1 .259.966l-.883.237.94.542 1.849-.495a.5.5 0 0 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-.94-.542.236.883a.5.5 0 0 1-.966.258L9.736 9.58 8.5 8.866v1.427l1.354 1.353a.5.5 0 0 1-.707.708l-.647-.647v1.086l1.354 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5z"/>
                </svg>
                Chłodnictwo
              </div>
              <div className={styles["branza"]}>
                <svg
                  viewBox="0 0 16 16"
                  className={styles["ikona-branza"]}
                >
                  <path d="M5 12.5a1.5 1.5 0 1 1-2-1.415V9.5a.5.5 0 0 1 1 0v1.585A1.5 1.5 0 0 1 5 12.5z"/>
                  <path d="M1 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM3.5 1A1.5 1.5 0 0 0 2 2.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0L5 10.486V2.5A1.5 1.5 0 0 0 3.5 1zm5 1a.5.5 0 0 1 .5.5v1.293l.646-.647a.5.5 0 0 1 .708.708L9 5.207v1.927l1.669-.963.495-1.85a.5.5 0 1 1 .966.26l-.237.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.884.237a.5.5 0 1 1-.26.966l-1.848-.495L9.5 8l1.669.963 1.849-.495a.5.5 0 1 1 .258.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.237.883a.5.5 0 1 1-.966.258L10.67 9.83 9 8.866v1.927l1.354 1.353a.5.5 0 0 1-.708.708L9 12.207V13.5a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5z"/>
                </svg>
                Klimatyzacja
              </div>
              <div className={styles["branza"]}>
                <svg viewBox="0 0 16 16" className={styles["ikona-branza"]}>
                  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                </svg>
                Ogrzewnictwo
              </div>
            </div>
            <div className={projectStyles["akapit-just"]}>
              Tworzymy niezawodne układy sterujące zarówno dla prostych
              instalacji, jak i&nbsp;dla złożonych systemów składających się z&nbsp;wielu
              instalacji w&nbsp;kilku budynkach.
            </div>
            <div className={projectStyles["pogrubiony"]}>Zapraszamy do współpracy!</div>
            <div className={projectStyles["button-container"]}>
              <Link
                to="/realizacje"
                className={projectStyles["button-black"]}
              >
                Realizacje
              </Link>
              <Link
                to="/o_firmie"
                className={projectStyles["button-white"]}
              >
                O&nbsp;nas
              </Link>
            </div>
          </div>
          <div className={styles["banner"]}>
            <img
              alt="grzawa"
              loading="lazy"
              src="/playground_assets/1674541554483.png"
              className={styles["banner-img"]}
            />
          </div>
        </div>
      </div>
      <div className={styles["sterowniki"]}>
        <div className={projectStyles["naglowek"]}>Sprawdzone rozwiązania</div>
        <div className={styles["ramka-carel"]}>
          <div className={projectStyles["tekst"]}>
            <div className={projectStyles["akapit-just"]}>
              W&nbsp;świecie automatyki HVAC kluczowe jest poszukiwanie rozwiązań, które zapewnią precyzyjną regulację,
              trwałość i&nbsp;niezawodność, a&nbsp;jednocześnie będą w&nbsp;przystępnej cenie. W&nbsp;przypadku naszej firmy, te właśnie 
              cechy są w&nbsp;centrum uwagi. Dlatego zdecydowaliśmy się na sterowniki włoskiej firmy Carel, która już
              od ponad 50 lat produkuje i&nbsp;dostarcza na rynek rozwiązania dla układów sterowania w chłodnictwie, klimatyzacji,
              nawilżaniu oraz dla instalacji wykorzystujących systemy zdalnego nadzoru i&nbsp;monitoringu. To, co wyróżnia te sterowniki, 
              to ich dedykacja dla przemysłu HVAC. Narzędzie do programowania wyposażone jest fabrycznie we wbudowane funkcje,
              stworzone przez zespół programistów Carela, które stanowią sprawdzone i&nbsp;zaawansowane narzędzia do
              precyzyjnego i&nbsp;niezawodnego sterowania urządzeniami. Dzięki gotowym blokom funkcyjnym tworzone aplikacje mimo 
              prostej budowy mogą realizować zaawansowane funkcje regulacji.
            </div>
            <div className={projectStyles["akapit-just"]}>
              Oferowane przez nas rozwiązania są wszechstronne. Bez względu na to, czy to chłodnictwo, ogrzewanie,
              wentylacja czy klimatyzacja - produkowane przez nas rozdzielnice znajdują zastosowanie w&nbsp;każdej instalacji.
              Nasze produkty oferują także praktycznie nieograniczone możliwości, umożliwiając tworzenie dowolnych
              aplikacji, jakie dana instalacja HVAC potrzebuje.
            </div>
            <div className={projectStyles["akapit-just"]}> 
              Jako firma, ciągle rozwijamy nasze rozwiązania. Każdy nowy projekt wzbogaca nas o&nbsp;nowe doświadczenia, 
              co pozwala nam na jeszcze lepszą jakość i&nbsp;dostosowanie się do zmieniających się potrzeb klientów.
              Nasze rozwiązania przyczyniają się nie tylko do niezawodności instalacji, ale także do oszczędności energii. 
              Dzięki opomiarowaniu instalacji i&nbsp;precyzyjnej regulacji urządzeniami, jesteśmy w&nbsp;stanie znacząco zredukować 
              jej zapotrzebowanie energetyczne.
            </div>
          </div>
          <div className={styles["sterownik"]}>
            <div className={styles["naglowek-carel"]}>
              <div className={projectStyles["podnaglowek"]}>
                Sterowniki PLC firmy Carel
              </div>
            </div>
            <div className={styles["karuzela"]}>
              <Carousel
                data={data}
                time={3000}
                width="auto"
                height="auto"
                captionStyle={captionStyle}
                radius="8px"
                slideNumber={false}
                slideNumberStyle={slideNumberStyle}
                captionPosition="bottom"
                automatic={true}
                dots={true}
                showNavBtn={false}
                pauseIconColor="#256861"
                pauseIconSize="24px"
                slideBackgroundColor="white"
                slideImageFit="cover"
                thumbnails={true}
                thumbnailWidth="75px"
                style={{
                  maxWidth: "100%",
                  marginBottom: "40px",
                  marginTop: "16px",
                  overflow: "hidden"
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={projectStyles["produkty-container"]}>
        <div className={projectStyles["max-container"]}>
          <div className={projectStyles["naglowek"]}>Skuteczna współpraca</div>
          <div className={projectStyles["tekst"]}>
            <div className={projectStyles["akapit-just"]}>
              W naszej działalności zdobyliśmy niezbędne doświadczenie, by oferować współpracę na miarę Twoich potrzeb. 
              Nasze rozwiązania znajdują zastosowanie w&nbsp;różnych obszarach – od obiektów użyteczności publicznej i&nbsp;zakładów produkcyjnych 
              po domy jednorodzinne. To dowód na naszą wszechstronność i&nbsp;umiejętność dostosowania się do różnorodnych wymagań klientów. 
              Nasza elastyczność, duży bagaż doświadczenia i&nbsp;konkurencyjne ceny stawiają nas w&nbsp;czołówce dostawców rozwiązań HVAC. 
              Wspólnie tworzymy innowacyjne układy sterowania, zapewniając Ci efektywne, niezawodne i&nbsp;przyszłościowe rozwiązania. 
              Z&nbsp;nami, Twoje projekty zyskują solidne wsparcie i&nbsp;pewność sukcesu.
            </div>
          </div>
          <div className={projectStyles["podnaglowek"]}>
            Współpraca z&nbsp;naszą firmą to:
          </div>
          <div className={projectStyles["produkty"]}>
            <div className={projectStyles["produkt"]}>
              <svg
                viewBox="0 0 16 16"
                className={projectStyles["ikona-produktu"]}
              >
                <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1V1Z"/>
                <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm4.386 1.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
              </svg>
              <div className={projectStyles["nazwa-produktu"]}>
                Inteligentne rozwiązania
              </div>
              <div className={projectStyles["opis-produktu"]}>
                  Tworzymy projekt obejmujący kompleksowe rozwiązania dla
                  inwestycji pozwalający na integrację wszystkich elementów
                  wykonawczych i&nbsp;zabezpieczających w&nbsp;ramach jednego układu
                  sterowania.
              </div>
            </div>
            <div className={projectStyles["produkt"]}>
              <svg
                viewBox="0 0 16 16"
                className={projectStyles["ikona-produktu"]}
              >
                <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
              </svg>
              <div className={projectStyles["nazwa-produktu"]}>
                Gwarancja
              </div>
              <div className={projectStyles["opis-produktu"]}>
                  Wszystkie układy sterowania przed przekazaniem klientowi
                  zostają, uruchomione, wstępnie skonfigurowane i&nbsp;sprawdzone.
                  Pozwala to na minimalizację czasu i&nbsp;nakładu pracy potrzebnego
                  do uruchomienia urządzenia.
              </div>
            </div>
            <div className={projectStyles["produkt"]}>
              <svg
                viewBox="0 0 16 16"
                className={projectStyles["ikona-produktu"]}
              >
                <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
              </svg>
              <div className={projectStyles["nazwa-produktu"]}>
                Wsparcie
              </div>
              <div className={projectStyles["opis-produktu"]}>
                  Zapewniamy pełne wsparcie przy montażu i&nbsp;uruchomieniu układu
                  sterowania. Atutem naszych rozwiązań jest elastyczność i&nbsp;możliwość 
                  późniejszej rozbudowy oraz częściowej zmiany zasady działania.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={projectStyles["tlo-srednie"]}>
        <div className={projectStyles["max-container"]}>
          <div className={styles["naglowek-container"]}>
            <div className={projectStyles["naglowek"]}>
              Szeroki zakres zastosowania
            </div>
            <div className={projectStyles["podnaglowek"]}>
              Tworzymy układy sterujące  dla urządzeń i&nbsp;instalacji takich jak:
            </div>
          </div>
          <div className={styles["systemy-sterowania"]}>
            <div className={styles["system"]}>
              <div className={styles["system-element"]}>
                <svg viewBox="0 0 1024 1024" className={styles["system-ikona"]}>
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <div className={styles["system-tekst"]}>
                  Centrale wentylacyjne każdego typu i&nbsp;w&nbsp;każdej konfiguracji
                </div>
              </div>
              <div className={styles["system-element"]}>
                <svg viewBox="0 0 1024 1024" className={styles["system-ikona"]}>
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <div className={styles["system-tekst"]}>
                  Agregaty skraplające i&nbsp;chłodnice w&nbsp;chłodnictwie przemysłowym
                </div>
              </div>
              <div className={styles["system-element"]}>
                <svg viewBox="0 0 1024 1024" className={styles["system-ikona"]}>
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <div className={styles["system-tekst"]}>
                  Instalacje grzewczo-chłodnicze w&nbsp;budynkach przemysłowych i&nbsp;
                  biurowych
                </div>
              </div>
            </div>
            <div className={styles["system"]}>
              <div className={styles["system-element"]}>
                <svg viewBox="0 0 1024 1024" className={styles["system-ikona"]}>
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <div className={styles["system-tekst"]}>
                    Instalacje centralnego ogrzewania, belki obiegów grzewczych
                    i&nbsp;węzły cieplne
                </div>
              </div>
              <div className={styles["system-element"]}>
                <svg viewBox="0 0 1024 1024" className={styles["system-ikona"]}>
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <div className={styles["system-tekst"]}>
                    Klimatyzacje przemysłowe i&nbsp;biurowe, również w&nbsp;układach
                    nietypowych i&nbsp;serwerowniach
                </div>
              </div>
              <div className={styles["system-element"]}>
                <svg viewBox="0 0 1024 1024" className={styles["system-ikona"]}>
                  <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                </svg>
                <div className={styles["system-tekst"]}>
                  Sterowanie oświetleniem i&nbsp;innymi elementami inteligentnego
                  budynku
                </div>
              </div>
            </div>
          </div>
          <Link
            to="/oferta"
            className={projectStyles["button-black"]}
          >
            Sprawdź zakres naszych usług
          </Link>
          <div className={projectStyles["fiveunits-margin"]}></div>
        </div>
      </div>
      <div className={styles["producenci"]}>
        <div className={projectStyles["naglowek"]}>
          Niezawodność
        </div>
        <div className={styles["tekst"]}>
          <div className={projectStyles["akapit-just"]}>
            W naszej firmie stawiamy na jakość i&nbsp;niezawodność. Naszym priorytetem jest zapewnienie klientom systemów 
            sterowania, które pracują bezawaryjnie i&nbsp;efektywnie. Dlatego wybieramy liderów w branży elektrycznej, 
            światowe marki z oddziałami na całym świecie. To one gwarantują niezawodność i&nbsp;wieloletnią pracę naszych 
            systemów, a&nbsp;jednocześnie stanowią solidną podstawę naszych innowacyjnych rozwiązań.
          </div>
        </div>
        <div className={projectStyles["podnaglowek"]}>
            Zaufanie w komponentach renomowanych marek:
        </div>
        <div className={styles["logo-container"]}>
          <a
            href="https://www.carel.pl"
            target="_blank"
            rel="noreferrer noopener"
            className={styles["link-icon"]}
          >
            <img
              alt="carel"
              src="/playground_assets/carel-200h.jpg"
              className={styles["logo"]}
            />
          </a>
          <a
            href="https://www.eaton.com/pl/pl-pl.html"
            target="_blank"
            rel="noreferrer noopener"
            className={styles["link-icon"]}
          >
            <img
              alt="eaton"
              src="/playground_assets/eaton-200h.jpg"
              className={styles["logo"]}
            />
          </a>
          <a
            href="https://www.se.com/pl/pl/"
            target="_blank"
            rel="noreferrer noopener"
            className={styles["link-icon"]}
          >
            <img
              alt="schneider"
              src="/playground_assets/schneider.png"
              className={styles["logo"]}
            />
          </a>
          <a
            href="https://www.weidmuller.pl/pl/index.jsp"
            target="_blank"
            rel="noreferrer noopener"
            className={styles["link-icon"]}
          >
            <img
              alt="weidmuller"
              src="/playground_assets/weidmuller-gruppe-vector-logo-200h.png"
              className={styles["logo"]}
            />
          </a>
          <a
            href="https://www.weintek.com/globalw/"
            target="_blank"
            rel="noreferrer noopener"
            className={styles["link-icon"]}
          >
            <img
              alt="weintek"
              src="/playground_assets/weintek-logo-200h.png"
              className={styles["logo"]}
            />
          </a>
          <a
            href="https://www.meanwell.com/"
            target="_blank"
            rel="noreferrer noopener"
            className={styles["link-icon"]}
          >
            <img
              alt="meanwell"
              src="/playground_assets/mean_well_logo-200h.png"
              className={styles["logo"]}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
